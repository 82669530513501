// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Privacy Policy Link
// - - - - - - - - - - - - - - -

///
/// @group login
///

@include component(captcha) {
  $color-input-border: color(border, dark);
  $color-button-bg: color(primary);

  .hs-captcha {
    &__input-container {
      display: flex;
      margin-top: gutter(6);
      border-radius: radius();

      @include respond-to(mobile) {
        flex-flow: column-reverse;
      }
    }

    &__input {
      height: auto;
      padding: gutter(16 0 16 8);
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      @include respond-to(mobile) {
        padding: gutter(12 0 12 8);
        margin-top: 0;
        border-top: 0;
        border-right: 1px solid $color-input-border;
        border-top-right-radius: 0;
        border-bottom-right-radius: radius();
        border-top-left-radius: 0;
      }
    }

    &__img-refresh-btn-wrapper {
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      justify-content: space-between;
      padding: gutter(3 6);
      border: 1px solid $color-input-border;
      border-radius: radius();
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      @include respond-to(mobile) {
        width: 100%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: radius();
      }
    }

    &__refresh-btn {
      display: inline-flex;
      margin-left: gutter(4);
      line-height: 0.8;
    }

    &__refresh-icon {
      fill: $color-button-bg;
    }
  }
}
