// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.

// - - - - - - - - - - - - - - - - - - - - - -
// Styles for anchor tags and links with icons
// - - - - - - - - - - - - - - - - - - - - - -

///
/// @group link
///

@include component(list) {
  $primary-color: color(primary);
  $secondary-color: color(secondary);
  $secondary-hover-color: color(secondary, dark);
  $disabled-link-color: color(primary, light);

  $icon-size: icon-size(small);
  $icon-size-xx-small: icon-size(xx-small);

  .hs-link {
    display: flex;
    align-items: center;
    font-size: font-size(body, base);
    line-height: 1.5;
    text-decoration: none;

    &__icon {
      font-size: $icon-size;
    }

    &--icon-left .hs-link__icon {
      margin-right: gutter(4);
    }

    &--icon-right .hs-link__icon {
      margin-left: gutter(4);
    }

    &--icon-xx-small {
      .hs-link__icon {
        font-size: $icon-size-xx-small;
      }
    }

    &--primary {
      color: $primary-color;
    }

    &--secondary {
      color: $secondary-color;

      &:hover {
        color: $secondary-hover-color;
      }
    }

    &--inline {
      display: inline-flex;
    }

    &--inherit-font-size {
      font-size: inherit;
    }

    &.disabled {
      color: $disabled-link-color;
    }
  }
}
