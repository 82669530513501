// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Login pages styles
// - - - - - - - - - - - - - - -

///
/// @group Layout
///

@include component(login-page) {
  .hs-login-page {
    width: 360px;
    margin: auto;

    &__header {
      padding: gutter(72 0 20);
      text-align: center;
    }

    &__login-header {
      margin-bottom: gutter(12);
    }

    &__forgot-password-wrapper {
      margin-top: gutter();
    }

    &__remember-me-wrapper {
      margin-bottom: gutter(12);
    }

    &--sso-only-login {
      .hs-login-page__login-header {
        text-align: center;
      }
    }
  }
}
