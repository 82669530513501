@charset "UTF-8";

/*
  @component icons

  Generate icon styles for given selector and icons code set.
*/

@import "../data/hesticons-icon-code";
@import "../generated-hesticon-version";

@font-face {
    font-family: "hesticons";
    src: url('/static/fonts/hesticons/hesticons.eot#{$hesticon-version}');
    src: url('/static/fonts/hesticons/hesticons.eot#{$hesticon-version}#iefix') format('eot'),
    url('/static/fonts/hesticons/hesticons.woff#{$hesticon-version}') format('woff'),
    url('/static/fonts/hesticons/hesticons.ttf#{$hesticon-version}') format('truetype'),
    url('/static/fonts/hesticons/hesticons.svg#{$hesticon-version}#hesticons') format('svg');

    font-weight: normal;
    font-style: normal;
}


%ion {
    display: inline-block;
    font-family: "hesticons";
    font-style: normal;
    font-size: 20px;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@each $icon-name, $icon-code in $hesticons-icon-code {

    // don't make properties like font-size too specific
    // they should be overridable by a bem like convention
    .ion-#{$icon-name} {
        @extend %ion;
    }

    // let codepoint be specific, adhering to our convention of
    // icons being only applicable to <i> tags
    i.ion-#{$icon-name} {
        &:before {
            content: "#{$icon-code}";
        }
    }
}
