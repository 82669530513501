// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Powered By Logo
// - - - - - - - - - - - - - - -

///
/// @group login
///

@include component(powered-by) {
  $wrapper-width: 130px;
  $wrapper-height: 24px;

  .hs-powered-by {
    width: $wrapper-width;
    margin: 0 auto;

    &__link {
      @include bg-img(transparent, "sprite.png", no-repeat, -55px, -36px, true, 290px, 632px);
      display: block;
      width: $wrapper-width;
      height: $wrapper-height;
      color: transparent;

      &:hover {
        color: transparent;
      }
    }
  }
}
