// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.
//
// Author: Bhargava Chowdary Mutyala (bhargava@helpshift.com)

// - - - - - - - - - - - - - - -
// Form Field Styles
// - - - - - - - - - - - - - - -

@include component(formfield) {
  // Form Field Margin
  $margin: gutter(0 0 20);

  $label-color: color(text);
  $label-font-weight: font-weight(bold);
  $label-margin: gutter(0 0 4);

  $defocussed-label-color: color(text, light);

  $description-color: color(text, light);
  $description-margin: gutter(-2 0 4);

  $info-color: color(text, light);

  $error-msg-weight: font-weight(bold);
  $error-msg-color: color(error, text);
  $error-msg-margin: gutter(2 0 0);

  $note-color: color(text, light);
  $top-border-color: color(border);

  $filter-row-error-bg: color(bg);

  $field-value-desc-color: color(text, light);
  $optional-text-color: color(text, light);

  .hs-formfield {
    width: 100%;
    margin: $margin;

    &__label-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__label {
      display: block;
      flex: 1;
      margin: $label-margin;
      font-weight: $label-font-weight;
      color: $label-color;
    }

    &__description {
      display: block;
      margin: $description-margin;
      color: $description-color;
    }

    &__field-info {
      color: $info-color;
    }

    &__note {
      margin-top: gutter(4);
      color: $note-color;
    }

    &__error-note {
      font-weight: $error-msg-weight;
      color: $error-msg-color;
    }

    &__error {
      display: block;
      margin: $error-msg-margin;
      font-weight: $error-msg-weight;
      color: $error-msg-color;
    }

    &__field-value-description {
      margin-top: gutter(8);
      color: $field-value-desc-color;
    }

    &__prefix {
      margin-left: gutter(8);
      font-weight: font-weight(bold);
    }

    &__suffix {
      margin: gutter(0 8);
    }

    &__optional-field-text {
      margin-left: gutter(3);
      font-weight: font-weight();
      color: $optional-text-color;
    }

    &--no-margin {
      margin: 0;
    }

    &--with-prefix,
    &--with-suffix {
      .hs-formfield__input-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: $_input-bg;
        border: 1px solid $_input-border;
        border-radius: $_input-radius;
        box-shadow: $_input-box-shadow;

        input[type="text"],
        input[type="password"],
        input[type="search"],
        input[type="email"],
        input[type="tel"],
        input[type="url"] {
          background: none;
          border: 0;
          box-shadow: none;

          &:focus {
            border: 0;
          }

          &[disabled] {
            // @NOTE: The default disabled styles of input (_forms.css) has
            // added the border using !important. So we are explicitly adding
            // !important to make following styles more specific as we want these
            // styles to take priority

            // stylelint-disable declaration-no-important
            border: 0 !important;
            // stylelint-enable declaration-no-important
            box-shadow: none;
          }
        }
      }

      .hs-formfield__input {
        min-width: 0;
      }

      &.hs-formfield--focus {
        .hs-formfield__input-wrapper {
          border-color: $_input-focus-border;
        }
      }

      &.hs-formfield--error,
      &.hs-formfield--invalid {
        .hs-formfield__input-wrapper {
          border-color: $_input-error-border;
        }
      }
    }

    &--with-prefix {
      .hs-formfield__input-wrapper {
        input[type="text"],
        input[type="password"],
        input[type="search"],
        input[type="email"],
        input[type="tel"],
        input[type="url"] {
          padding-left: gutter(2);
        }
      }
    }

    &--horizontal {
      display: flex;
      align-items: center;

      .hs-formfield__label {
        flex: 0 0 auto;
        margin: gutter(0 8 0 0);
      }

      .hs-formfield__input,
      .hs-formfield__input-wrapper {
        flex: 1 1 auto;
      }
    }

    &--deemphasized-label {
      .hs-formfield__label {
        color: $defocussed-label-color;
      }
    }

    &--small {
      .hs-formfield__prefix,
      .hs-formfield__suffix {
        margin: gutter(0 4);
      }
    }

    &--x-small {
      .hs-formfield__prefix,
      .hs-formfield__suffix {
        margin: gutter(0 4);
      }
    }

    &--with-top-separator {
      padding-top: gutter(20);
      margin-top: gutter(20);
      border-top: 1px solid $top-border-color;
    }

    &--auto-width {
      width: auto;
    }

    &--filter-row {
      margin: 0;

      .hs-formfield__error {
        position: relative;
        font-weight: $error-msg-weight;
        background: $filter-row-error-bg;
      }
    }

    &--segment-duration-input {
      margin: 0 0 0 gutter(4);
    }

    &--large {
      .hs-formfield__label {
        font-size: font-size(body, large);
      }
    }
  }
}
