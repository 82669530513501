// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.

///
/// @group mixins
///

/// Hardware Accelerate animations/transforms
@mixin accelerate($name) {
  will-change: $name;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@mixin bg-img(
  $clr,
  $src,
  $repeat: no-repeat,
  $x: 0,
  $y: 0,
  $include-2x: false,
  $width: false,
  $height: false
) {
  $root: "../images/";

  @if ($src == "sprite.png") {
    $src: "#{ $src }?v=20160628";
  }

  $path: "#{$root}#{$src}";

  background: $clr url($path) $repeat $x $y;

  @if $include-2x != false {
    @if ($width != false and $height != false) {
      @include respond-to(retina) {
        $dot-index: str-index($src, ".");
        $src-2x: str-insert($src, "@2x", $dot-index);

        $path-2x: "#{$root}#{$src-2x}";
        background-image: url($path-2x);
        background-size: $width $height;
      }
    } @else {
      @error "You need to mention the width and height of the image to add its 2x version.";
    }
  }
}

/// Applies the micro clearfix hack popularized by Nicolas Gallagher. Include this mixin on a container if its children are all floated, to give the container a proper height.
/// The clearfix is augmented with specific styles to prevent borders in flexbox environments
/// @link http://nicolasgallagher.com/micro-clearfix-hack/ Micro Clearfix Hack
/// @link http://danisadesigner.com/blog/flexbox-clear-fix-pseudo-elements/ Flexbox fix
@mixin clearfix {
  &::before,
  &::after {
    display: table;
    flex-basis: 0;
    order: 1;
    content: " ";
  }

  &::after {
    clear: both;
  }
}

@mixin css-spinner($pe: after, $color: color(primary), $size: 24px) {
  &:#{$pe} {
    @include animation(rotate360, 0.6s, 0, linear, none, visible, infinite);

    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $size;
    height: $size;
    margin: -#{$size / 2} 0 0 -#{$size / 2};
    color: $color;
    border: 1px solid $color;
    border-width: 0 1px 2px 2px;
    border-radius: radius(round);
    content: "";
  }
}

// ellipsis
@mixin ellipsis($width: 100%) {
  width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/// Create equal side border radius on elements.
///
/// @author: Bhargava Chowdary M <bhargava@helpshift.com>
///
/// @param {String} $side - left, right, top, bottom
/// @param {Number} $radius [radius()] - Border radius value
@mixin side-radius($side, $radius: radius()) {
  @if $side == left {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  } @else if $side == right {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  } @else if $side == top {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  } @else if $side == bottom {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

/// Custom Scrollbar
@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;

    &-track {
      background: transparent none;
      border: 4px solid transparent;
    }

    &-thumb {
      background: rgba(color(bg, lightblack), 0.45);
      border: 2px solid transparent;
      border-radius: 20px;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      background: rgba(color(bg, lightblack), 0.75);
    }
  }
}

/// Creates a CSS triangle, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the triangle to an existing element.
///
/// @param {Number} $triangle-size - Width of the triangle.
/// @param {Color} $triangle-color - Color of the triangle.
/// @param {Keyword} $triangle-direction - Direction the triangle points. Can be `up`, `right`, `down`, or `left`.
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  $down-triangle-border-color: $triangle-color transparent transparent;
  $up-triangle-border-color: transparent transparent $triangle-color;
  $right-triangle-border-color: transparent transparent transparent $triangle-color;
  $left-triangle-border-color: transparent $triangle-color transparent transparent;
  $top-left-triangle-border-color: $triangle-color transparent transparent;
  $top-right-triangle-border-color: $triangle-color transparent transparent;
  $bottom-left-triangle-border-color: transparent transparent $triangle-color;

  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  content: "";

  @if ($triangle-direction == down) {
    border-color: $down-triangle-border-color;
    border-top-style: solid;
    border-bottom-width: 0;
  }
  @if ($triangle-direction == up) {
    border-color: $up-triangle-border-color;
    border-bottom-style: solid;
    border-top-width: 0;
  }
  @if ($triangle-direction == right) {
    border-color: $right-triangle-border-color;
    border-left-style: solid;
    border-right-width: 0;
  }
  @if ($triangle-direction == left) {
    border-color: $left-triangle-border-color;
    border-right-style: solid;
    border-left-width: 0;
  }
  @if ($triangle-direction == topleft) {
    border-color: $top-left-triangle-border-color;
    border-top-style: solid;
    border-left-width: 0;
  }
  @if ($triangle-direction == topright) {
    border-color: $top-right-triangle-border-color;
    border-top-style: solid;
    border-right-width: 0;
  }
  @if ($triangle-direction == bottomleft) {
    border-color: $bottom-left-triangle-border-color;
    border-bottom-style: solid;
    border-left-width: 0;
  }
}

/// Creates a component while automatically adding typography styles to
/// the component and its children.
///
/// @param {String} $name - Name of the component.
/// @param {Boolean} $include-typography-styles [false] - Option to choose whether to include the typography styles for a particular component.
@mixin component($name, $include-typography-styles: false) {
  // Remove "hs-" from the name if it's included
  $index: str-index($name, "hs-");
  @if $index == 1 {
    $name: quote(str-slice($name, 4));
  }

  // Apply new Typography styles to the component and its children.
  @if $include-typography-styles {
    .hs-#{$name} {
      @extend %hs-typography-styles;
      @include set-default-type;
    }
  }

  // Adding body to force an increase in specificity of component styles.
  //
  // Without this the above Typography styles override the component styles.
  body {
    @content;
  }
}

/// Add striped progress bar in the background of the element
@mixin striped-progress-bar($opacity: 0.25) {
  @include animation(move, $duration: 0.75s, $function: linear, $count: infinite);
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, $opacity) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, $opacity) 50%,
    rgba(255, 255, 255, $opacity) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
}

@mixin loading-progress {
  @include striped-progress-bar;
  @include animation(move, $duration: 1s, $function: linear, $count: infinite);
  cursor: default;
}

// Media Queries
@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: 767px) {
      @content;
    }
  } @else if $media == small {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  } @else if $media == tablets {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $media == touch {
    @media only screen and (max-width: 1280px) {
      .touch & {
        @content;
      }
    }
  } @else if $media == retina {
    /* stylelint-disable unit-whitelist */
    /* prettier-ignore */
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      // https://developer.mozilla.org/en/CSS/Media_queries
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min-device-pixel-ratio: 1.5),
      // Refer: https://github.com/h5bp/html5-boilerplate/issues/1178
      only screen and (min-resolution: 1.5dppx) {
      @content;
      /* stylelint-enable unit-whitelist */
    }
  }
}

@mixin respond-to-width($min-width: false, $max-width: false) {
  @if $min-width and $max-width {
    @media only screen and (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  } @else if $min-width {
    @media only screen and (min-width: $min-width) {
      @content;
    }
  } @else if $max-width {
    @media only screen and (max-width: $max-width) {
      @content;
    }
  }
}

// Hide-show-state
// To show and hide particular notice, label, etc on presence of a class
// on container
@mixin show-hide-when($state, $display: block) {
  .show-when-#{ $state } {
    /* stylelint-disable declaration-no-important */
    display: none !important;
  }

  &.show-#{ $state } {
    .show-when-#{ $state } {
      display: $display !important;
    }

    .hide-when-#{ $state } {
      display: none !important;
      /* stylelint-enable declaration-no-important */
    }
  }
}

@mixin word-break {
  word-break: break-all;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  word-break: break-word;
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin rotate($degrees) {
  -ms-transform: rotate(#{$degrees}deg);
  -webkit-transform: rotate(#{$degrees}deg);
  transform: rotate(#{$degrees}deg);
}

@mixin no-outline {
  border: 0;
  outline: 0;
  box-shadow: none;
}

@mixin flex-center($justify-content-value: null) {
  display: flex;
  align-items: center;

  @if $justify-content-value != null {
    justify-content: $justify-content-value;
  }
}
