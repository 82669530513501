@charset "UTF-8";

@import "utils/functions";
@import "utils/mixins";

@import "vendor/normalize";
@import "global";
@import "typography";
@import "forms";

@include hs-global-styles;
@include hs-typography-resets;
@include hs-typography-styles;

@import "animations/animations";

@import "components/layout/login-page";

@import "components/button";
@import "components/link";
@import "components/forms/form";
@import "components/forms/formfield";
@import "components/forms/checkbox";

@import "components/login/captcha";
@import "components/login/privacy-policy";
@import "components/login/powered-by";
@import "components/login/third-party-login";
@import "components/login/message-box";
@import "components/login/form-box";
@import "components/login/pwd-indicator-wrapper";
@import "components/password-complexity/password-complexity-indicator";
@import "components/password-complexity/password-constraint";

$body-bg: color(bg, lightgray);

body {
  background: $body-bg;
}

// >> Hestia
// ----------------------------------------------------------------------------

@import "hestia/components/icons";
