// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Styles for forms
// - - - - - - - - - - - - - - -

///
/// @group form
///

@include component(form) {
  /// Active form background
  $active-bg: color(alert, bg);

  .hs-form {
    margin: 0;

    &--active {
      background-color: $active-bg;
    }
  }
}
