// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Buttons Component
// - - - - - - - - - - - - - - -

///
/// @group Button
///

/// Line height for buttons
/// @type Number
$hs-button-line-height: 1;

/// Line height for buttons with only icons
/// @type Number
$hs-button-icon-only-line-height: 0;

/// Default fill for buttons. Can either be `solid` or `hollow`.
/// @type Keyword
$hs-button-fill: solid;

/// Background colors for solid and hollow buttons (default, hover and active).
/// @type Map
$hs-button-bg: (
  solid: (
    base: (
      primary: color(primary),
      secondary: color(secondary)
    ),
    hover: (
      primary: color(primary, dark),
      secondary: color(secondary, dark)
    ),
    active: (
      primary: color(primary, x-dark),
      secondary: color(secondary, x-dark)
    )
  ),
  hollow: (
    base: (
      primary: transparent,
      secondary: transparent
    ),
    hover: (
      primary: color(primary, xx-light),
      secondary: color(secondary, xx-light)
    ),
    active: (
      primary: color(primary, x-light),
      secondary: color(secondary, x-light)
    )
  )
);

/// Font colors for buttons.
/// @type Map
$hs-button-colors: (
  solid: (
    primary: color(text, white),
    secondary: color(text, white)
  ),
  hollow: (
    primary: color(primary),
    secondary: color(secondary)
  )
);

/// Font sizes for buttons.
/// @type Map
$hs-button-font-sizes: (
  base: (
    normal: 14px,
    icon: 24px,
    icon-only: 28px,
    icon-dropdown: 6px
  ),
  small: (
    normal: 14px,
    icon: 20px,
    icon-only: 24px,
    icon-dropdown: 5px
  ),
  x-small: (
    normal: 12px,
    icon: 18px,
    icon-only: 24px,
    icon-dropdown: 5px
  ),
  xx-small: (
    normal: 12px,
    icon: 16px,
    icon-only: 20px,
    icon-dropdown: 4px
  )
);

/// Paddings for all button sizes.
/// @type Map
$hs-button-paddings: (
  base: (
    normal: 12px 20px,
    icon: 12px,
    icon-only: 5px
  ),
  small: (
    normal: 8px 16px,
    icon: 12px,
    icon-only: 3px
  ),
  x-small: (
    normal: 7px 12px,
    icon: 8px,
    icon-only: 1px
  ),
  xx-small: (
    normal: 5px 8px,
    icon: 4px,
    icon-only: 1px
  )
);

/// Box Shadow for buttons on Hover and Active states
$hs-button-box-shadow: rgba(0, 0, 0, 0.15) 0 1px 4px;

/// Border radius for base and small buttons
/// @type Number
$hs-button-radius: radius();

/// Border radius for x-small and xx-small buttons
/// @type Number
$hs-button-radius-small: radius(small);

$hs-button-radius-x-large: radius(x-large);

/// opacity for a disabled button.
/// @type List
$hs-button-disabled-opacity: 0.5;

/// Add base styles for buttons
/// @param {Keyword} $size [base] - Size of the button to add styles for.
@mixin button-base($size: base, $content: normal) {
  $lh: $hs-button-line-height;

  @if $content == icon-only {
    $lh: $hs-button-icon-only-line-height;
  }

  position: relative;
  display: inline-block;
  height: auto;
  padding: map-fetch($hs-button-paddings, $size, normal);
  font-size: map-fetch($hs-button-font-sizes, $size, normal);

  @if $content == icon-only or $content == icon {
    [class*="ion-"],
    .hs-button__icon {
      font-size: map-fetch($hs-button-font-sizes, $size, $content);
    }
  }

  @if $content == icon-only {
    padding: map-fetch($hs-button-paddings, $size, $content);
    font-size: map-fetch($hs-button-font-sizes, $size, $content);
  }

  font-weight: font-weight(bold);
  line-height: $lh;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: $hs-button-radius;

  @if $size == xx-small {
    border-radius: $hs-button-radius-small;
  }

  outline: 0;
  transition: background-color 0.125s ease-out;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/// Expands a button to make it full-width.
/// @param {Boolean} $expand [true] - Set to `true` to enable the expand behavior. Set to `false` to reverse this behavior.
@mixin button-expand($expand: true) {
  @if $expand {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  } @else {
    display: inline-block;
    width: auto;
  }
}

/// Sets the visual style of a button.
/// @param {Keyword} $type [primary] - Type of button to style.
/// @param {String} $fill [solid|hollow] - Button fill.
@mixin button-style($type: primary, $fill: $hs-button-fill) {
  $colors-map: map-get($hs-button-colors, $fill);
  $bg-map: map-get($hs-button-bg, $fill);
  $border-color: transparent;
  $button-color: map-fetch($colors-map, $type);
  $button-bg: map-fetch($bg-map, base, $type);
  $button-hover-bg: map-fetch($bg-map, hover, $type);
  $button-active-bg: map-fetch($bg-map, active, $type);

  @if ($fill == hollow) {
    // Border color for hollow buttons should be
    // the same as the text color.
    $border-color: map-fetch($hs-button-colors, hollow, $type);
  }

  color: $button-color;
  background-color: $button-bg;
  border-color: $border-color;

  &:hover,
  &:focus {
    color: $button-color;
    text-decoration: none;
    background-color: $button-hover-bg;
    box-shadow: $hs-button-box-shadow;
  }

  &:active {
    background-color: $button-active-bg;
    box-shadow: none;
  }
}

/// Adds disabled styles to a button by fading the element, reseting the cursor, and disabling pointer events.
/// @param {Keyword} $type [primary] - Type of button to style.
@mixin button-disabled($type: primary, $fill: $hs-button-fill) {
  $disabled-color: map-fetch($hs-button-colors, $fill, $type);
  $disabled-bg: map-fetch($hs-button-bg, $fill, base, $type);

  cursor: not-allowed;
  opacity: $hs-button-disabled-opacity;

  &:hover,
  &:focus {
    color: $disabled-color;
    background-color: $disabled-bg;
    box-shadow: none;
  }
}

@mixin button-loading-style($type: primary, $fill: $hs-button-fill) {
  @if ($fill == hollow) {
    $background: map-fetch($hs-button-bg, $fill, active, $type);
    background-color: $background;

    &:hover,
    &:focus {
      background-color: $background;
    }
  }
  @include striped-progress-bar;
}

@mixin button-loading($type: primary, $fill: $hs-button-fill) {
  @include button-disabled($type, $fill);
  @include button-loading-style($type, $fill);
}

/// Adds all styles for a button. For more granular control over styles, use the individual button mixins.
/// @param {Keyword} $type [primary] - Type of button to style.
/// @param {Keyword} $size [base] - Size of the button.
/// @param {Keyword} $style [solid] - Set to `hollow` to create a hollow button.
/// @param {Boolean} $expand [false] - Set to `true` to make the button full-width.
@mixin button(
  $type: primary,
  $size: base,
  $fill: $hs-button-fill,
  $expand: false,
  $content: normal
) {
  @include button-base($size, $content);
  @include button-style($type, $fill);

  @if $expand {
    @include button-expand;
  }
}

@include component(button, $include-typography-styles: false) {
  .hs-button {
    @include button;

    // Disabled style
    &[disabled],
    &.disabled, // Add this temporarily to work with legacy code.
    &--disabled {
      @include button-disabled;
    }

    // Expanded Button
    &--expanded {
      @include button-expand;
    }

    $_icon-font-size: map-fetch($hs-button-font-sizes, base, icon);
    $_icon-pad: map-fetch($hs-button-paddings, base, icon);
    $_icon-only-font-size: map-fetch($hs-button-font-sizes, base, icon-only);
    $_icon-only-pad: map-fetch($hs-button-paddings, base, icon-only);

    $_icon-dropdown-size: map-fetch($hs-button-font-sizes, base, icon-dropdown);

    &--loading {
      @include button-loading;
    }

    // Secondary Button
    &--secondary {
      @include button-style(secondary);

      &[disabled],
      &.hs-button--disabled {
        @include button-disabled(secondary);
      }

      &.hs-button--loading {
        @include button-loading-style($type: secondary);
      }
    }

    // Hollow Buttons
    &--hollow {
      @include button($fill: hollow);

      &[disabled],
      &.hs-button--disabled,
      &.hs-button--loading {
        @include button-disabled($fill: hollow);
      }

      &.hs-button--loading {
        @include button-loading($fill: hollow);
      }

      &.hs-button--secondary {
        @include button-style(secondary, hollow);

        &[disabled],
        &.hs-button--disabled {
          @include button-disabled(secondary, hollow);
        }

        &.hs-button--loading {
          @include button-loading($type: secondary, $fill: hollow);
        }
      }
    }

    &--icon-left,
    &--icon-right,
    &--dropdown {
      text-align: left;

      [class*="ion-"],
      .hs-button__icon {
        position: absolute;
        top: 50%;
        left: $_icon-pad;
        margin-top: $_icon-font-size * -0.5;
        font-size: $_icon-font-size;
      }

      // Added to support non-standard button type
      // in Bulk Action Edit Modal.
      //
      // Need to be updated to make this a proper Button variation
      .hs-button__count {
        min-width: 24px;
        padding: gutter(6);
        font-size: font-size(body, small);
        text-align: center;
        background: rgba(color(primary, x-dark), 0.2);
        border-radius: radius(round);
      }
    }

    &--icon-left {
      .hs-button__text {
        padding-left: $_icon-font-size;
      }

      .hs-button__count {
        margin-left: gutter(-4);
      }
    }

    &--icon-right,
    &--dropdown {
      [class*="ion-"],
      .hs-button__icon {
        right: $_icon-pad;
        left: auto;
      }

      .hs-button__text {
        padding-right: $_icon-font-size;
      }

      .hs-button__count {
        margin-right: gutter(-4);
      }
    }

    &--icon-only {
      padding: $_icon-only-pad;
      font-size: $_icon-only-font-size;
      line-height: 0;

      [class*="ion-"],
      .hs-button__icon {
        font-size: $_icon-only-font-size;
      }
    }

    // Button with icons on both left and right side
    &--dual-icons {
      .button-icon {
        position: absolute;
        top: 50%;
        margin-top: $_icon-font-size * -0.5;
        font-size: $_icon-font-size;

        &--left {
          left: $_icon-pad;
        }

        &--right {
          right: $_icon-pad;
          left: auto;
        }
      }

      .button-text {
        padding-left: $_icon-font-size;

        &--right {
          padding-right: $_icon-font-size;
        }
      }
    }

    &--x-large-radius {
      border-radius: $hs-button-radius-x-large;
    }

    // For Buttons that work as Dropdown Title.
    //
    // Adding this modifier will render an arrow inside the button.
    // The arrow will always point downwards and is rendered to the right of the
    // Button label.
    &--dropdown {
      $_icon-dropdown-color: map-fetch($hs-button-colors, solid, primary);

      .hs-button__icon {
        margin-top: gutter($_icon-dropdown-size * -0.5);
        margin-right: gutter($_icon-dropdown-size * 0.5);

        &::after {
          @include css-triangle($_icon-dropdown-size, $_icon-dropdown-color, down);
        }
      }

      &.hs-button--hollow {
        &.hs-button--dropdown {
          $_icon-dropdown-color: map-fetch($hs-button-colors, hollow, primary);

          .hs-button__icon {
            &::after {
              @include css-triangle($_icon-dropdown-size, $_icon-dropdown-color, down);
            }
          }
        }

        &.hs-button--secondary {
          $_icon-dropdown-color: map-fetch($hs-button-colors, hollow, secondary);

          .hs-button__icon {
            &::after {
              @include css-triangle($_icon-dropdown-size, $_icon-dropdown-color, down);
            }
          }
        }
      }
    }

    // Different Button Sizes - small, x-small, xx-small
    @each $size, $value in map-remove($hs-button-font-sizes, base) {
      &--#{$size} {
        padding: map-fetch($hs-button-paddings, $size, normal);
        font-size: map-get($value, normal);

        $_icon-pad: map-fetch($hs-button-paddings, $size, icon);
        $_icon-font-size: map-get($value, icon);
        $_icon-only-font-size: map-get($value, icon-only);

        $_icon-dropdown-size: map-fetch($value, icon-dropdown);

        &.hs-button--icon-only {
          padding: map-fetch($hs-button-paddings, $size, icon-only);
          font-size: $_icon-only-font-size;
          line-height: $hs-button-icon-only-line-height;

          [class*="ion-"],
          .hs-button__icon {
            font-size: $_icon-only-font-size;
          }
        }

        &.hs-button--icon-left,
        &.hs-button--icon-right,
        &.hs-button--dropdown {
          [class*="ion-"],
          .hs-button__icon {
            margin-top: $_icon-font-size * -0.5;
            font-size: $_icon-font-size;
          }
        }

        &.hs-button--icon-left {
          [class*="ion-"],
          .hs-button__icon {
            left: $_icon-pad;
          }

          .hs-button__text {
            padding-left: $_icon-font-size;
          }
        }

        &.hs-button--icon-right,
        &.hs-button--dropdown {
          [class*="ion-"],
          .hs-button__icon {
            right: $_icon-pad;
            left: auto;
          }

          .hs-button__text {
            padding-right: $_icon-font-size;
          }
        }

        // Increased specificity by adding double classed to fix
        // styling issues when hs-button--secondary is used along with
        // hs-button--dropdown
        &.hs-button--dropdown.hs-button--dropdown {
          .hs-button__icon {
            margin-top: gutter($_icon-dropdown-size * -0.5);
            margin-right: gutter($_icon-dropdown-size * 0.5);

            &::after {
              border-style: inset;
              border-top-style: solid;
              border-width: $_icon-dropdown-size;
            }
          }
        }

        @if $size == xx-small {
          border-radius: $hs-button-radius-small;
        }
      }
    }
  }
}
