// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Base Typography
// - - - - - - - - - - - - - - -

// These are styles applied to basic HTML tags, including:
//   - Paragraphs <p>
//   - Bold/italics <b> <strong> <i> <em>
//   - Small text <small>
//   - Headings <h1>—<h6>
//   - Anchors <a>
//   - Dividers <hr>
//   - Lists <ul> <ol> <dl>
//   - Blockquotes <blockquote>
//   - Code blocks <code>
//   - Abbreviations <abbr>
//   - Citations <cite>
//   - Keystrokes <kbd>

////
/// @group typography
////

/// Font family for header elements.
/// @type String | List
$header-font-family: $_font-family;

/// Font weight of headers.
/// @type String
$header-font-weight: font-weight(bold);

/// Font style (e.g. italicized) of headers.
/// @type String
$header-font-style: normal;

/// Color of headers.
/// @type Color
$header-color: color(text, dark);

/// Bottom margin of headers.
/// @type Number
$header-margin-bottom: gutter(8);

/// Text rendering method of headers.
/// @type String
$header-text-rendering: optimizeLegibility;

/// Font size of `<small>` elements.
/// @type Number
$small-font-size: font-size(body, small);

/// Font size of `<small>` elements inside heading.
/// @type Number
$small-header-font-size: 80%;

/// Font size of `<small class="tiny">` elements.
/// @type Number
$tiny-font-size: font-size(body, tiny);

/// Color of `<small>` elements when placed inside headers.
/// @type Color
$header-small-font-color: color(text, light);

/// Line height of text inside `<p>` elements.
/// @type Number
$paragraph-lineheight: line-height();

/// Bottom margin of paragraphs.
/// @type Number
$paragraph-margin-bottom: gutter(16);

/// Text rendering method for paragraph text.
/// @type String
$paragraph-text-rendering: optimizeLegibility;

/// Text color of code samples.
/// @type Color
$code-color: color(text);

/// Text color of when code is inactive
/// @type Color
$code-inactive-color: color(text, x-light);

/// Font family of code samples.
/// @type String | List
$code-font-family: $_font-family-monospace;

/// Font weight of text in code samples.
/// @type String
$code-font-weight: font-weight(normal);

/// Background color of code samples.
/// @type Color
$code-background: color(bg, lightgray);

/// Border around code samples.
/// @type List
$code-border: 1px solid color(border);

/// Padding around text of code samples.
/// @type Number | List
$code-padding: 2px 5px 1px;

/// Font family of keyboard keys text.
/// @type String | List
$kbd-font-family: $_font-family-monospace;

/// Font weight of keyboard keys text.
/// @type String
$kbd-font-weight: font-weight(normal);

/// Text color of keyboard keys text.
/// @type Color
$kbd-color: color(text, dark);

/// Default color for links.
/// @type Color
$anchor-color: color(primary);

/// Default color for links on hover.
/// @type Color
$anchor-hover-color: color(primary, dark);

/// Default text deocration for links.
/// @type String
$anchor-text-decoration: none;

/// Default text decoration for links on hover.
/// @type String
$anchor-text-decoration-hover: none;

/// Maximum width of a divider.
/// @type Number
$hr-width: 100%;

/// Default border for a divider.
/// @type List
$hr-border: 1px solid color(border);

/// Default margin for a divider.
/// @type Number | List
$hr-margin: gutter(20) auto;

/// Line height for items in a list.
/// @type Number
$list-lineheight: $paragraph-lineheight;

/// Bottom margin for items in a list.
/// @type Number
$list-margin-bottom: $paragraph-margin-bottom;

/// Bullet type to use for unordered lists (e.g., `square`, `circle`, `disc`).
/// @type String
$list-style-type: disc;

/// Positioning for bullets on unordered list items.
/// @type String
$list-style-position: outside;

/// Left (or right) margin for lists.
/// @type Number
$list-side-margin: gutter();

/// Bottom margin for `<dl>` elements.
/// @type Number
$defnlist-margin-bottom: gutter(16);

/// Font weight for `<dt>` elements.
/// @type String
$defnlist-term-weight: font-weight(bold);

/// Spacing between `<dt>` and `<dd>` elements.
/// @type Number
$defnlist-term-margin-bottom: gutter(4);

@mixin set-default-type($family: $_font-family) {
  font-family: $family;
  font-size: font-size(body);
  font-weight: font-weight(normal);
  line-height: line-height(body);
  color: color(text);
}

@mixin hs-typography-resets() {
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  p,
  blockquote,
  th,
  td {
    padding: 0;
    margin: 0;
  }
}

@mixin hs-typography-styles($family: $header-font-family) {
  // Paragraphs
  p {
    margin-bottom: $paragraph-margin-bottom;
    font-size: inherit;
    line-height: $paragraph-lineheight;
    color: color(text);
    text-rendering: $paragraph-text-rendering;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  // Emphasized text
  em,
  i {
    &:not([class*="ion-"]) {
      font-style: italic;
      line-height: inherit;
    }
  }

  // Strong text
  strong,
  b {
    font-weight: font-weight(bold);
    line-height: inherit;
  }

  // Small text
  small {
    font-size: $small-font-size;
    line-height: inherit;

    &.font--tiny {
      font-size: $tiny-font-size;
    }
  }

  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: $header-margin-bottom;
    font-family: $family;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
    line-height: line-height();
    color: $header-color;
    text-rendering: $header-text-rendering;
    text-transform: none;

    small {
      font-size: $small-header-font-size;
      line-height: 0;
      color: $header-small-font-color;
    }

    &.no-margin {
      margin-bottom: 0;
    }
  }

  // Heading sizes
  @each $header, $font-size in map-get($_font-sizes, heading) {
    #{$header} {
      font-size: $font-size * 1px;
      line-height: line-height($header);
    }
  }

  // Links
  a {
    line-height: inherit;
    color: $anchor-color;
    text-decoration: $anchor-text-decoration;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $anchor-hover-color;
      text-decoration: $anchor-text-decoration-hover;
    }

    img {
      border: 0;
    }
  }

  // Horizontal rule
  hr {
    height: 0;
    max-width: $hr-width;
    margin: $hr-margin;
    clear: both;
    border-top: 0;
    border-right: 0;
    border-bottom: $hr-border;
    border-left: 0;
  }

  // Lists
  ul,
  ol,
  dl {
    margin-bottom: $list-margin-bottom;
    line-height: $list-lineheight;
    list-style-position: $list-style-position;
  }

  // List items
  li {
    font-size: inherit;
  }

  // Unordered lists
  ul {
    margin-left: $list-side-margin;
    list-style-type: $list-style-type;
  }

  // Ordered lists
  ol {
    margin-left: $list-side-margin;
  }

  // Definition lists
  dl {
    margin-bottom: $defnlist-margin-bottom;

    dt {
      margin-bottom: $defnlist-term-margin-bottom;
      font-weight: $defnlist-term-weight;
    }
  }

  pre,
  code {
    font-family: $code-font-family;
    font-weight: $code-font-weight;
  }

  // Code
  code {
    padding: $code-padding;
    margin: 0;
    color: $code-color;
    background-color: $code-background;
    border: $code-border;

    &.no-frame {
      padding: 0;
      background: none;
      border: 0;
    }

    &.wrap-text {
      @include word-break;
    }

    &.inactive {
      color: $code-inactive-color;
    }

    &.unselectable {
      // Safari
      -webkit-user-select: none;
      // Firefox
      -moz-user-select: none;
      // IE10+/Edge
      -ms-user-select: none;
      // Standard
      user-select: none;
    }
  }

  // Kbd
  kbd {
    font-family: $kbd-font-family;
    font-weight: $kbd-font-weight;
    color: $kbd-color;
  }
}

%hs-typography-styles {
  @include hs-typography-styles;
}
