// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Global variables and styles
// - - - - - - - - - - - - - - -

////
/// @group global
////

/// Color palette used for the dashboard
/// @type Map
$_palettes: (
  black: (
    x-dark: #212528,
    dark: #383f44,
    base: #576066,
    light: #859199,
    x-light: #b6c3cc,
    xx-light: #d5dfe6
  ),
  blue: (
    x-dark: #0e498c,
    dark: #215fa6,
    base: #3d80cc,
    light: #67a2e6,
    x-light: #a6cfff,
    xx-light: #ebf4ff
  ),
  cyan: (
    x-dark: #7ba8b3,
    dark: #91c1cc,
    base: #a4ddea,
    light: #c4ecf5,
    x-light: #d9f4fa,
    xx-light: #edfafc
  ),
  green: (
    x-dark: #19803c,
    dark: #2e9952,
    base: #43bf6c,
    light: #8dd9a6,
    x-light: #b0ebbf,
    xx-light: #e6faeb
  ),
  gray: (
    x-dark: #a2a4a6,
    dark: #c8cacc,
    base: #d4d7d9,
    light: #e8e9eb,
    x-light: #f8f9f9,
    xx-light: #fff
  ),
  red: (
    x-dark: #c33,
    dark: #e65050,
    base: #f66,
    light: #ff8c8c,
    x-light: #ffb3b3,
    xx-light: #ffe6e6
  ),
  yellow: (
    x-dark: #d99100,
    dark: #f2b230,
    base: #ffc859,
    light: #ffd580,
    x-light: #ffe1a6,
    xx-light: #fff5e0
  )
) !global;

/// Colors for global elements on the dashboard
/// @type Map
$_colors: (
  alert: (
    bg: palette(yellow, xx-light),
    bg-dark: palette(yellow, x-light),
    border: palette(yellow),
    border-dark: palette(yellow, dark),
    text: palette(yellow, dark),
    text-dark: palette(yellow, x-dark)
  ),
  bg: (
    black: palette(black, x-dark),
    lightblack: palette(black, dark),
    darkgray: palette(black),
    gray: palette(gray, light),
    lightgray: palette(gray, x-light),
    base: palette(gray, xx-light),
    white: palette(grey, xx-light),
    xx-dark: palette(black, x-dark),
    x-dark: palette(black, dark),
    light: palette(gray, light),
    x-light: palette(gray, x-light),
    xx-light: palette(grey, xx-light)
  ),
  border: (
    x-dark: palette(gray, x-dark),
    dark: palette(gray, dark),
    base: palette(gray),
    light: palette(gray, light),
    x-light: palette(gray, x-light)
  ),
  button: (
    base: palette(blue),
    black: palette(black),
    green: palette(green),
    red: palette(red),
    yellow: palette(yellow)
  ),
  error: (
    bg: palette(red, xx-light),
    bg-dark: palette(red, x-light),
    border: palette(red),
    border-dark: palette(red, dark),
    text: palette(red, dark),
    text-dark: palette(red, x-dark)
  ),
  info: (
    bg: palette(blue, xx-light),
    bg-dark: palette(blue, x-light),
    border: palette(blue),
    border-dark: palette(blue, dark),
    text: palette(blue, dark),
    text-dark: palette(blue, x-dark)
  ),
  impersonator: (
    bg: palette(red, dark),
    icon: palette(red, light),
    nav-active: palette(red, dark)
  ),
  primary: (
    x-dark: palette(blue, x-dark),
    dark: palette(blue, dark),
    base: palette(blue),
    light: palette(blue, light),
    x-light: palette(blue, x-light),
    xx-light: palette(blue, xx-light)
  ),
  secondary: (
    x-dark: palette(red, x-dark),
    dark: palette(red, dark),
    base: palette(red),
    light: palette(red, light),
    x-light: palette(red, x-light),
    xx-light: palette(red, xx-light)
  ),
  success: (
    bg: palette(green, xx-light),
    bg-dark: palette(green, x-light),
    border: palette(green),
    border-dark: palette(green, dark),
    text: palette(green, dark),
    text-dark: palette(green, x-dark)
  ),
  text: (
    dark: palette(black, dark),
    base: palette(black),
    light: palette(black, light),
    x-light: palette(black, x-light),
    white: palette(gray, xx-light),
    warning: palette(yellow, dark)
  ),
  warning: (
    bg: palette(yellow, xx-light),
    bg-dark: palette(yellow, x-light),
    border-light: palette(yellow, light),
    border: palette(yellow),
    border-dark: palette(yellow, dark),
    text: palette(yellow, dark),
    text-dark: palette(yellow, x-dark)
  )
) !global;

/// Font sizes for body and header elements
/// @type Map
$_font-sizes: (
  body: (
    large: 16,
    base: 14,
    small: 12,
    tiny: 11
  ),
  heading: (
    h1: 24,
    h2: 21,
    h3: 18,
    h4: 16,
    h5: 14,
    h6: 12
  ),
  button: (
    tiny: 12,
    small: 14,
    base: 16,
    large: 18
  )
) !global;

/// Line heights for body and header elements
/// @type Map
$_line-heights: (
  base: 1.5,
  body: 1.5,
  tiny: 1.454545455,
  form: 1.6,
  h1: 32px,
  h2: 28px,
  h3: 24px,
  h4: 20px,
  h5: 20px,
  h6: 16px
) !global;

/// Font weights for body and header elements
/// @type Map
$_font-weights: (
  normal: 400,
  medium: 600,
  semibold: 600,
  bold: 600
) !global;

/// Font sizes for icons
/// @type Map
$_icon-sizes: (
  mega-large: 64,
  xxx-large: 28,
  xx-large: 26,
  x-large: 24,
  large: 22,
  base: 20,
  small: 18,
  x-small: 16,
  xx-small: 14,
  xxx-small: 12
) !global;

/// Base for gutter (margins, paddings) values used for components.
/// @type Number
$_gutter-base: 4px;

/// Border radius
/// @type Map
$_radius: (
  x-large: 16,
  large: 8,
  base: 4,
  small: 2,
  round: 50%
) !global;

/// Map to manage z-indexes of elements
/// @type Map
/* prettier-ignore */
$_z-index: (
  issues: (
    adv-search: 10,
    list-header: 9,
    /// Should be more than the z-index of preview-resize
    /// in order to keep the sort drop-down above the vertical
    /// preview resize line.
    list-header-container: 120,
    // Sort separator icon just needs a z-index so that it renders
    // above the sort items.
    sort-separator-icon: 20,
    view-header-tabs: 3,
    view-header-info: 2
  ),
  page: (
    base: 100,
    progress-bar: 900,
    sidebar-toggle: 110,
    preview-resize: 110
  ),
  select: (
    title: 500,
    content: 910
  ),
  smartviews: (
    manager: 9000
  ),
  custom-checkbox: 10,
  dropdown: 9999,
  data-table: (
    fixed-header: 9
  ),
  // Order should be - block-refresh > blocker > tooltip > growl > modal > nav
  nav: 10000,
  modal: 10071,
  growl: 10076,
  tooltip: 10081,
  blocker: 10086,
  block-refresh: 10091,
  modal-above-blocker: 10096
) !global;

/// Map to manage widths of global components
/// @type Map
$_widths: (
  main-nav: (
    base: 72,
    mobile: 64
  ),
  sidebar: (
    base: 300,
    mobile: 260,
    content: 340,
    content-mobile: 300
  )
);

/// Font stack used for elements that should be rendered in system fonts
/// @type String | List
$_font-family-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
  Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

/// Default font stack for all elements.
/// @type String | List
$_font-family: "myriad-pro", #{$_font-family-system};

/// Font stack used for elements that use monospaced type, such as code samples
/// @type String | List
$_font-family-monospace: Consolas, "Liberation Mono", Menlo, Courier, monospace;

/// Background color of the body.
/// @type Color
$_body-background: color(bg, white);

/// Text color of the body.
/// @type Color
$_body-font-color: color(text);

/// Font stack of the body.
/// @type List
$_body-font-family: $_font-family;

/// Set to `true` to enable antialiased type, using the `-webkit-font-smoothing` and `-moz-osx-font-smoothing` CSS properties.
/// @type Boolean
$_body-antialiased: true;

@mixin hs-global-styles() {
  @include _hs-normalize;

  html {
    font-size: font-size(body);
    box-sizing: border-box;
  }

  // Set box-sizing globally to handle padding and border widths
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  // Default body styles
  body {
    font-family: $_body-font-family;
    font-weight: font-weight(normal);
    line-height: line-height(body);
    color: $_body-font-color;
    background: $_body-background;

    @if ($_body-antialiased) {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }
  }
}
