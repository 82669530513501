// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Password Indicator Wrapper
// - - - - - - - - - - - - - - -

///
/// @group login
///

@include component(pwd-indicator-wrapper) {
  $pwd-indicator-wrapper-height: 64px;

  .hs-pw-indicator-wrapper {
    height: $pwd-indicator-wrapper-height;
    margin-bottom: gutter(8);
  }
}
