// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// 3rd party login buttons
// - - - - - - - - - - - - - - -

///
/// @group login
///

@include component(third-party-login) {
  $separator-color: color(border);

  .hs-third-party-login {
    padding: gutter(12 0 0);
    margin-top: gutter(12);
    border-top: 1px solid $separator-color;

    &__forms {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      margin-top: gutter(12);
    }

    &__form {
      padding-right: gutter(12);
      padding-bottom: gutter(12);

      &:last-child {
        padding-right: 0;
      }
    }

    &--sso {
      padding-top: 0;
      margin-top: gutter(20);
      border-top: 0;
    }

    &--sso-only-login {
      .hs-third-party-login__forms {
        justify-content: center;
      }
    }
  }
}
