// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Login Form Box
// - - - - - - - - - - - - - - -

///
/// @group login
///

@include component(login-form-box) {
  $login-form-bg: color(bg, white);
  $login-form-border: color(border);

  .hs-login-form-box {
    padding: gutter(20);
    margin-bottom: gutter(20);
    background-color: $login-form-bg;
    border: 1px solid $login-form-border;
    border-radius: radius();

    &__change-pwd-later-link {
      margin-top: gutter();
      text-align: center;
    }
  }
}
