// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2016, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Styles for checkbox
// - - - - - - - - - - - - - - -

///
/// @group checkbox
///

@include component(checkbox) {
  $checkbox-color: color(text);
  $checkbox-border: color(border, dark);
  $checkbox-bg: color(bg, white);
  $tick-color: color(primary);
  $checkbox-dimension: 16px;
  $icon-hyphen-width: 10px;
  $icon-hyphen-height: 2px;

  $label-left-margin: gutter(8);
  $desc-left-margin: $checkbox-dimension + $label-left-margin;

  .hs-checkbox {
    position: relative;
    display: flex;
    margin: 0;
    cursor: pointer;

    &__input {
      position: absolute;
      z-index: 10;
      width: $checkbox-dimension;
      height: $checkbox-dimension;
      margin-top: gutter(2);
      cursor: pointer;
      visibility: hidden;
      appearance: none;
    }

    &__fake-box {
      position: relative;
      z-index: 1;
      display: block;
      flex: 0 0 auto;
      width: $checkbox-dimension;
      height: $checkbox-dimension;
      margin-top: gutter(2);
      cursor: pointer;
      background: $checkbox-bg;
      border: 1px solid $checkbox-border;
      border-radius: radius(small);
      box-shadow: rgba(0, 0, 0, 0.12) 1px 1px 2px inset;
    }

    .ion-tick {
      position: absolute;
      top: -5px;
      display: none;
      font-size: 22px;
      color: $tick-color;
    }

    &__icon-hyphen {
      position: absolute;
      top: 6px;
      left: 2px;
      display: block;
      width: $icon-hyphen-width;
      height: $icon-hyphen-height;
      background: $tick-color;
    }

    &__input[type="checkbox"]:checked + .hs-checkbox__fake-box {
      .ion-tick {
        display: block;
      }
    }

    &__label {
      margin: gutter(0 0 0 $label-left-margin);
      color: $checkbox-color;
      user-select: none;
    }

    &__description {
      margin-left: $desc-left-margin;
      color: $checkbox-color;
    }

    &--boxed {
      padding: gutter(8);
      background: $checkbox-bg;
      border: 1px solid $checkbox-border;
      border-radius: radius();
    }

    &--bold {
      .hs-checkbox__label {
        font-weight: font-weight(bold);
      }
    }

    &--vertically-spaced {
      .hs-checkbox__label {
        margin-bottom: gutter(8);
      }
    }

    &--disabled {
      cursor: not-allowed;

      .hs-checkbox__input,
      .hs-checkbox__fake-box {
        cursor: not-allowed;
      }
    }

    &--full-width-label {
      .hs-checkbox__label {
        width: 100%;
      }
    }

    &--with-desc {
      flex-direction: column;

      .hs-checkbox__input-label-wrapper {
        display: flex;
      }
    }

    &--word-break-all {
      word-break: break-all;
    }
  }
}
