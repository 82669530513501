// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Privacy Policy Link
// - - - - - - - - - - - - - - -

///
/// @group login
///

@include component(privacy-policy) {
  .hs-privacy-policy {
    margin: gutter(0 0 12);
    text-align: center;
  }
}
