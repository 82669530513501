// Styles for Helpshift Admin Dashboard
// [helpshift.com](https://helpshift.com)
// Copyright 2018, Helpshift Inc.

// - - - - - - - - - - - - - - -
// Privacy Policy Link
// - - - - - - - - - - - - - - -

///
/// @group login
///

@include component(hs-login-message-box) {
  $msg-box-bg: color(bg, white);
  $msg-box-border: color(border);

  .hs-login-message-box {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding: gutter(20);
    margin-bottom: gutter(20);
    background-color: $msg-box-bg;
    border: 1px solid $msg-box-border;
    border-radius: radius();
  }
}
